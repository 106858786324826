<template>
  <div>
    <b-list-group>
      <b-list-group-item to="/website/sports-offered">Sports Offerred</b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: 'sports-offered'
}
</script>